<template>
  <div class="csn-mm">
    <section class="section-menu-buttons">
      <div class="row-fluid csn-mm-header-btn-container">
        <div class="col-md-6">
          <Route :to="{ name: route.homepage }">
            <i v-if="isCasinoPlatin" class="glyphicon glyphicon-home"></i>
            <FiveHomeIcon v-else />
          </Route>
        </div>
        <div class="col-md-6 text-right">
          <a class="close-btn" @click="TOGGLE_MAIN_MENU">
            <CloseIcon />
          </a>
        </div>
      </div>
    </section>

    <div v-if="hasSession" class="csn-mm-session-info">
      <section class="section-cards csn-mm-section-card">
        <ClubCard class="csn-mm-card" />
      </section>
      <section class="section-dashboard csn-mm-section-dashboard">
        <div class="row-fluid csn-mm-dashboard-container">
          <div class="col-md-6">
            <p class="title">{{ 'Platin Coins' }}</p>
            <p class="amount">
              {{ platinCoins }}
              <img src="@/assets/images/coints-icon.png" class="platin-icon" />
            </p>
          </div>
          <div class="col-md-6">
            <Route
              :to="{ name: route.dashboard }"
              class="btn casino-btn casino-btn-default casino-btn-dashboard"
            >
              {{ t('dashboard') }}
            </Route>
          </div>
        </div>
      </section>
      <section class="section-dashboard csn-mm-section-dashboard-mobile">
        <ClubStatusIcon />
        <div @click="openSearchMenu" class="csn-mm-nav-icon casino-btn-default">
          <span class="casino-icon casino-icon-search"></span>
        </div>
        <UserNotificationButton
          :hasNotifications="hasNotifications"
          :listLength="listLength"
          @toggle="openUserNotifications"
        />
        <MyProfileButton @toggle="openMyAccountSideMenu" />
      </section>
      <section class="section-balance">
        <div class="row-fluid csn-mm-balance-container">
          <Route :to="{ name: route.depositHistory }">
            <div class="col-md-6">
              <p class="title">{{ 'Balance' }}</p>
              <p class="amount">{{ CASINO_INFO.accountBalance }}</p>
            </div>
          </Route>
          <div class="col-md-6">
            <Route
              :to="{ name: route.deposit }"
              class="btn casino-btn casino-btn-pink casino-btn-deposit"
            >
              {{ t('deposit') }}
            </Route>
          </div>
        </div>
      </section>
    </div>

    <div v-else>
      <section class="section-register">
        <p class="description">
          {{ t('register_description') }}
        </p>
        <p class="csn-mm-register-container">
          <Route
            :to="{ name: route.registration }"
            class="btn casino-btn casino-btn-pink csn-mm-register"
          >
            {{ t('register') }}
          </Route>
        </p>
        <p class="login-text csn-mm-login-desktop">
          {{ t('already_registered') }}
          <Route :to="{ name: route.login }" class="login-btn">
            {{ t('click_here_to_login') }}
          </Route>
        </p>
        <Route
          :to="{ name: route.login }"
          class="btn login-btn csn-mm-login-mobile casino-btn casino-btn-default"
        >
          {{ t('login') }}
        </Route>
        <button
          @click="handlePayAndPlayClick"
          class="btn casino-btn casino-main-menu-btn csn-nav-btn csn-nav-btn-main-menu csn-nav-dap-btn casino-btn-green csn-mm-dap-btn"
        >
          {{ t('deposit_and_play') }}
        </button>
      </section>
    </div>
    <div class="main-menu csn-mm-list">
      <ul>
        <li v-if="hasSession" class="csn-mm-list-li">
          <Route :to="setPathIfCasinoInfo(route.game)" class="csn-mm-list-a">
            <span class="casino-icon casino-icon-lobby"></span>
            {{ t('lobby') }}
          </Route>
        </li>
        <li
          @click="openGameCategoryMenu"
          class="csn-mm-list-games csn-mm-list-li"
        >
          <a class="csn-mm-list-a">
            <span
              v-if="isCasinoPlatin"
              class="casino-icon casino-icon-games"
            ></span>
            <FiveGamesIcon v-else />
            {{ t('games') }}
          </a>
          <SlideArrow />
        </li>
        <li @click="openGameMenu" class="csn-mm-list-providers csn-mm-list-li">
          <a class="csn-mm-list-a">
            <span
              v-if="isCasinoPlatin"
              class="casino-icon casino-icon-games-providers"
            ></span>
            <FiveGameProvidersIcon v-else />
            {{ t('games_providers') }}
          </a>
          <SlideArrow />
        </li>
        <li class="csn-mm-list-li">
          <Route :to="{ name: route.offers }" class="csn-mm-list-a">
            <span
              v-if="isCasinoPlatin"
              class="casino-icon casino-icon-offers"
            ></span>
            <FiveOffersIcon v-else />
            {{ t('offers') }}
          </Route>
        </li>
      </ul>
    </div>
    <div>
      <ul class="csn-mm-misc-list">
        <li @click="openLanguageMenu" class="csn-mm-misc-list-language">
          <a class="csn-mm-misc-list-language-a">
            <LanguageIconBase />
            {{ t('language') }}
          </a>
          <SlideArrow />
        </li>
      </ul>
      <ul class="csn-mm-misc-list" v-if="isMobileScreen">
        <li>
          <a>
            <span class="casino-icon casino-icon-chat-support"></span>
            {{ t('support') }}
          </a>
        </li>
        <li>
          <a>
            <span class="casino-icon casino-icon-information"></span>
            {{ t('information') }}
          </a>
        </li>
        <li v-if="hasSession">
          <a @click="logout">
            <span class="casino-icon casino-icon-logout"></span>
            {{ t('logout') }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import {
  RouteName,
  Module,
  TOGGLE_MAIN_MENU,
  TOGGLE_SEARCH_MENU,
  MAIN_MENU,
  MAIN_MENU_GAME,
  MAIN_MENU_GAME_CATEGORY,
  TOGGLE_MAIN_MENU_COMPONENT,
  CASINO_INFO,
  HASH,
  USER_NOTIFICATION_LIST,
  IS_USER_NOTIFICATION_LIST_PENDING,
  TOGGLE_USER_NOTIFICATION_SIDE_MENU,
  TOGGLE_MY_ACCOUNT_SIDE_MENU,
  IS_MOBILE_SCREEN,
  MAIN_MENU_LANGUAGE,
  TOGGLE_DESKTOP_NOTIFICATION_MENU,
  SHOW_MODAL,
  PAY_AND_PLAY_MODAL,
  TOGGLE_PAY_AND_PLAY_SIDE_MENU,
  CASINO_PLATIN,
} from '@/constants'
import { isEmpty, logout, dest, commitMutation } from '@/helpers'
import * as CasinoInfo from '@/models/getters/casinoInfo'

export default {
  name: MAIN_MENU,
  components: {
    ClubCard: () => import('@/components/ClubCard'),
    Route: () => import('@/components/Route'),
    CloseIcon: () => import('@/components/svg/CloseIcon'),
    SlideArrow: () => import('@/components/svg/SlideArrow'),
    ClubStatusIcon: () => import('@/components/ClubStatusIcon'),
    UserNotificationButton: () =>
      import(
        '@/components/UserNotifications/components/UserNotificationButton'
      ),
    MyProfileButton: () => import('@/components/MyProfileButton'),
    LanguageIconBase: () => import('@/components/svg/LanguageIconBase'),
    FiveHomeIcon: () => import('@/components/svg/FiveHomeIcon'),
    FiveGamesIcon: () => import('@/components/svg/FiveGamesIcon'),
    FiveGameProvidersIcon: () =>
      import('@/components/svg/FiveGameProvidersIcon'),
    FiveOffersIcon: () => import('@/components/svg/FiveOffersIcon'),
  },
  computed: {
    ...mapState(Module.USER_NOTIFICATION, [
      USER_NOTIFICATION_LIST,
      IS_USER_NOTIFICATION_LIST_PENDING,
    ]),
    ...mapState(Module.CASINO_INFO, [CASINO_INFO]),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    t() {
      return this.$createComponentTranslator(MAIN_MENU)
    },
    hasCasinoInfo() {
      return !isEmpty(this.CASINO_INFO)
    },
    hasSession() {
      return CasinoInfo.getSession(this.CASINO_INFO)
    },
    platinCoins() {
      return CasinoInfo.getPlatinCoins(this.CASINO_INFO)
    },
    route() {
      return {
        dashboard: RouteName.DASHBOARD,
        deposit: RouteName.DEPOSIT,
        depositHistory: RouteName.USER_DEPOSIT_HISTORY,
        homepage: RouteName.HOME,
        registration: RouteName.REGISTRATION,
        login: RouteName.LOGIN,
        game: RouteName.GAME,
        offers: RouteName.OFFERS,
      }
    },
    isEmptyList() {
      return isEmpty(this.USER_NOTIFICATION_LIST)
    },
    hasNotifications() {
      return !this.IS_USER_NOTIFICATION_LIST_PENDING && !this.isEmptyList
    },
    listLength() {
      return Number(this.USER_NOTIFICATION_LIST?.length)
    },
    isCasinoPlatin: () => process.env.VUE_APP_THEME === CASINO_PLATIN,
  },
  methods: {
    ...mapMutations(Module.MAIN, [
      TOGGLE_MAIN_MENU,
      TOGGLE_MAIN_MENU_COMPONENT,
      TOGGLE_SEARCH_MENU,
      TOGGLE_PAY_AND_PLAY_SIDE_MENU,
      TOGGLE_USER_NOTIFICATION_SIDE_MENU,
      TOGGLE_MY_ACCOUNT_SIDE_MENU,
      TOGGLE_DESKTOP_NOTIFICATION_MENU,
    ]),
    openGameMenu() {
      this.TOGGLE_MAIN_MENU_COMPONENT({ value: MAIN_MENU_GAME })
    },
    openGameCategoryMenu() {
      this.TOGGLE_MAIN_MENU_COMPONENT({ value: MAIN_MENU_GAME_CATEGORY })
    },
    openLanguageMenu() {
      this.TOGGLE_MAIN_MENU_COMPONENT({ value: MAIN_MENU_LANGUAGE })
    },
    setPathIfCasinoInfo(name) {
      return this.hasCasinoInfo ? { name } : HASH
    },
    async openSearchMenu() {
      await this.TOGGLE_MAIN_MENU()
      this.TOGGLE_SEARCH_MENU(true)
    },
    async openUserNotifications() {
      await this.TOGGLE_MAIN_MENU()
      this.isMobileScreen
        ? this.TOGGLE_USER_NOTIFICATION_SIDE_MENU(true)
        : this.TOGGLE_DESKTOP_NOTIFICATION_MENU(true)
    },
    async openMyAccountSideMenu() {
      await this.TOGGLE_MAIN_MENU()
      this.TOGGLE_MY_ACCOUNT_SIDE_MENU(true)
    },
    async logout() {
      await logout({ nextRoute: { name: RouteName.HOME } })
    },
    async handlePayAndPlayClick() {
      await this.TOGGLE_MAIN_MENU()

      this.isMobileScreen
        ? this.TOGGLE_PAY_AND_PLAY_SIDE_MENU(true)
        : commitMutation([Module.MODAL, SHOW_MODAL], {
            component: PAY_AND_PLAY_MODAL,
            isStrict: true,
          })
    },
  },
}
</script>

<style scoped></style>
